import { FC } from 'react';
import { Box } from '@mui/material';
import { Text } from '@confidant-health/lib/ui/atoms/typography';
import { Icons, IGlyphs } from '@confidant-health/lib/icons';
import { colors } from '@confidant-health/lib/colors';
import { iconBtnType, IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { ChatRecommendationEnums } from 'constants/CommonConstants';
import clsx from 'clsx';
import { useStyles } from './RecommendationBox.styles';

type Props = {
  recommendation: any;
  member: any;
  onClose: () => void;
  onAction: (entityType: string) => void;
};

const RecommendationBox: FC<Props> = ({ recommendation, member, onClose, onAction }) => {
  const { entityType, actionType, rationale, issueType } = recommendation || {};

  const classes = useStyles();

  const renderHeaderText = () => {
    let icon;
    let text;
    let textColor;

    if (entityType === ChatRecommendationEnums.EntityType.APPOINTMENT) {
      text = 'Opportunity';
      icon = 'wand-outlined';
      textColor = colors.success500;
    } else {
      text = issueType;
      icon = 'star';
      textColor = colors.secondary500;
    }

    return (
      <Box sx={{ display: 'flex', gap: 1 }}>
        <Icons
          className={clsx(icon === 'wand-outlined' ? classes.headerIconLarge : classes.headerIcon)}
          glyph={icon}
          color={textColor}
        />
        <Text
          sx={{ fontSize: '14px !important', color: `${textColor} !important`, fontWeight: '600 !important' }}
        >
          {text} Detected
        </Text>
      </Box>
    );
  };

  const renderBodyContent = () => {
    let title;

    if (entityType === ChatRecommendationEnums.EntityType.APPOINTMENT)
      title = (
        <>
          want to <span>Schedule an Appointment</span>
        </>
      );
    else
      title = (
        <>
          be having a <span>{issueType}</span>
        </>
      );

    return (
      <Box className={classes.boxBody}>
        <Text className={classes.bodyTitle}>
          {member?.nickname ?? 'Member'} may {title}
        </Text>
        <Text className={classes.bodySubTitle}>
          <span>Rationale: </span>
          {rationale}
        </Text>
      </Box>
    );
  };

  const renderFooterBtn = () => {
    const { EntityType, ActionType } = ChatRecommendationEnums;

    let icon = 'notes-icon';
    let type = 'Case Note';
    let action = actionType === ActionType.CREATE ? 'Create' : 'Update';

    // Appointment-specific overrides
    if (entityType === EntityType.APPOINTMENT) {
      icon = 'calendar-primary-outlined-square';
      type = 'Appointment';
      action = actionType === ActionType.CREATE ? 'Schedule' : 'Reschedule';
    }

    return (
      <IconButton
        variant={iconBtnType.TEXT}
        className={classes.footerBtn}
        onClick={() => onAction(entityType)}
        icon={icon as IGlyphs['glyph']}
      >
        {action} {type}
      </IconButton>
    );
  };

  return (
    <Box
      className={clsx(classes.boxContainer, {
        [classes.boxContainerVisible]: Boolean(recommendation),
      })}
    >
      {/* Header */}
      <Box className={classes.boxHeader}>
        <Box>{renderHeaderText()}</Box>
        <IconButton icon="close" className={classes.closeBtn} onClick={onClose} />
      </Box>

      {/* Body */}
      <Box>{renderBodyContent()}</Box>

      {/* Footer */}
      <Box className={classes.boxFooter}>{renderFooterBtn()}</Box>
    </Box>
  );
};

export default RecommendationBox;
