import { AppointmentStatus, S3_BUCKET_URL } from '@confidant-health/lib/constants/CommonConstants';
import dayjs from 'dayjs';
import moment from 'moment';
import { IGoogleCalender } from 'services/profile/profile.service';
import {
  dayConstants,
  DomainTypesEnum,
  frequencyConstants,
  GLOBAL_DATE_FORMAT,
  OPENTOK_APIKEY,
} from 'constants/CommonConstants';
import isYesterday from 'dayjs/plugin/isYesterday';
import { startOrJoinCall } from 'services/appointment/appointment.service';

import { HistoryLookup, IDomainElement, IDomainElementDetail } from '../redux/modules/profile/types';

dayjs.extend(isYesterday);

type JWT = {
  email?: string;
  authorities: Array<string>;
  exp: number;
  iat: number;
  sub: string;
};

export const parseJwt = (token: string): JWT => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(c => {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join(''),
  );

  return JSON.parse(jsonPayload);
};

export function groupTimeSlotsByDate(objectsArray) {
  const dateSet: string[] = [];
  objectsArray.forEach(obj => {
    const date = dayjs(obj.start).format('YYYY-MM-DD'); // Assuming each object has a "date" property
    dateSet.push(date);
  });
  const filteredDates = dateSet.filter((value, index) => dateSet.indexOf(value) === index);
  const result: any = [];
  filteredDates.forEach(date => {
    const slots = objectsArray.filter(obj => dayjs(obj.start).format('YYYY-MM-DD') === date);
    result.push({ date, slots });
  });
  return result;
}

export const getDateLabel = (date: string, format?: string) => {
  const tomorrow = dayjs().add(1, 'day');
  const isTomorrow = dayjs(date).isSame(tomorrow, 'day');
  return dayjs(date).isToday()
    ? 'Today'
    : isTomorrow
    ? 'Tomorrow'
    : dayjs(date).format(format ?? 'dddd, MMMM DD');
};

export const getDateLabels = (date: string, format?: string) => {
  const tomorrow = dayjs().add(1, 'day');
  const isTomorrow = dayjs(date).isSame(tomorrow, 'day');
  return dayjs(date).isToday()
    ? `Today ${dayjs(date).format('h:mm a')}`
    : isTomorrow
    ? `Tomorrow ${dayjs(date).format('h:mm a')}`
    : dayjs(date).isYesterday()
    ? `Yesterday ${dayjs(date).format('h:mm a')}`
    : dayjs(date).format(format ?? 'MMMM DD, h:mm a');
};

export const getDuration = service => {
  if (service?.duration) {
    // Convert to hours and minutes
    const hours = Math.floor(service.duration / 60);
    const minutes = service.duration % 60;
    if (hours > 0) {
      return `${hours} hour${hours > 1 ? 's' : ''} ${minutes} minutes`;
    }
    if (minutes > 0) {
      return `${minutes} minutes`;
    }
  }
  return null;
};

export const arrayUniqueByKey = (array: any[], key: string) => {
  return Array.from(new Map(array.map(item => [item[key], item])).values());
};

export const getUserAuthority = (token: string): string => {
  return parseJwt(token).authorities[0].replace('ROLE_', '');
};

export const formatTimeMessage = (time: string): string => {
  const date = dayjs(time);
  const now = dayjs();
  const diffMinutes = now.diff(date, 'minute');
  const diffHours = now.diff(date, 'hour');
  const diffDays = now.diff(date, 'day');

  if (diffMinutes < 1) {
    return 'Just now';
  }
  if (diffMinutes < 60) {
    return `${diffMinutes} minutes ago`;
  }
  if (diffHours < 24) {
    return date.format('h:mm a');
  }
  if (diffDays < 7) {
    return date.format('dddd');
  }
  return date.format('MMM DD, YYYY');
};

// convert minutes to hours
export const convertMinutesToHours = (minutes: number): string => {
  if (minutes < 60) {
    return `${minutes} minutes`;
  }

  const hours = Math.floor(minutes / 60);
  const minutesLeft = minutes % 60;
  return `${hours > 0 ? `${hours} ${hours > 1 ? 'hours' : 'hour'}` : ''} ${
    minutesLeft > 0 ? `${minutesLeft} ${minutesLeft > 1 ? 'minutes' : 'minute'}` : ''
  }`;
};

// convert second to HH:MM:SS
export const convertSecondToTime = (second: number): string => {
  const hours = Math.floor(second / 3600);
  const minutes = Math.floor((second - hours * 3600) / 60);
  const seconds = second - hours * 3600 - minutes * 60;
  const mmss = `${minutes > 9 ? '' : 0}${minutes}:${seconds > 9 ? '' : 0}${seconds}`;

  if (hours) {
    return `${hours > 9 ? '' : 0}${hours}:${mmss}`;
  }
  return mmss;
};

export const isAppointmentDone = (status: AppointmentStatus): boolean => {
  return [AppointmentStatus.FULFILLED, AppointmentStatus.CANCELLED, AppointmentStatus.NO_SHOW].includes(
    status,
  );
};

export const convertTimeWithDate = (date: string, time: number): string => {
  const hours = Math.floor(time / 100);
  const minutes = time % 100;
  const newDate = dayjs(date).set('hour', hours).set('minute', minutes).set('second', 0);
  return newDate.toISOString();
};

export const convertSlotToDate = (date: string, time: number): string => {
  const hours = Math.floor(time / 100);
  const minutes = time % 100;
  const newDate = new Date(date);
  newDate.setHours(hours);
  newDate.setMinutes(minutes);
  return newDate.toString();
};

export const shortNumber = (num: number): string => {
  if (num >= 1000000) {
    return `${(num / 1000000).toFixed(1)}M`;
  }
  if (num >= 1000) {
    return `${(num / 1000).toFixed(1)}K`;
  }
  return num?.toString();
};

export const getLastNDays = (days: number): string[] => {
  const today = new Date();
  const lastNDays = [];
  for (let i = 0; i < days; i += 3) {
    const date = new Date(today.getFullYear(), today.getMonth(), today.getDate() - i);
    lastNDays.push(date.toISOString());
  }

  return lastNDays.reverse();
};

export const getDurationUnit = (duration: number, minimize?: boolean) => {
  const minutesText = minimize ? 'min' : 'minutes';
  const hoursText = minimize ? 'hr' : 'hour';
  const hours = Math.floor(duration / 60);
  const minutes = duration % 60;
  if (hours === 0) return `${minutes} ${minutesText}`;
  if (minutes === 0) return `${hours} ${hoursText}`;
  return `${hours} ${hoursText} ${minutes} ${minutesText}`;
};

export const getCostRange = uniqueService => {
  if (uniqueService.providerServices.length === 1) {
    return `$${uniqueService.cost}`;
  }
  const minPrice = Math.min(
    ...uniqueService.providerServices.map(providerService => providerService.serviceDetails?.cost),
  );
  const maxPrice = Math.max(
    ...uniqueService.providerServices.map(providerService => providerService.serviceDetails?.cost),
  );
  if (Number.isNaN(minPrice) || Number.isNaN(maxPrice)) {
    return `$${uniqueService.cost}`;
  }
  if (minPrice === maxPrice) {
    return `$${minPrice}`;
  }
  return `$${minPrice} - $${maxPrice}`;
};

export const getLast7Days = (): string[] => {
  const today = new Date();
  const last7Days = [];
  for (let i = 0; i < 7; i += 3) {
    const date = new Date(today.getFullYear(), today.getMonth(), today.getDate() - i);
    last7Days.push(date.toISOString());
  }

  return last7Days.reverse();
};

export const getDctCBIds = (oldArr, objArr) => {
  const changedOrders = [];
  for (let i = 0; i < oldArr.length; i++) {
    const oldItem = oldArr[i];
    const objItem = objArr[i];

    if (oldItem.dctContentBlockList && objItem.dctContentBlockList) {
      const oldDctIds = oldItem.dctContentBlockList.map(item => item.cbId);
      const objDctIds = objItem.dctContentBlockList.map(item => item.cbId);

      const isDctOrderChanged = oldDctIds.some((id, index) => id !== objDctIds[index]);

      if (isDctOrderChanged) {
        changedOrders.push({
          dctId: objItem.dctId,
          cbId: objItem.cbId,
          ids: objDctIds,
        });
      }
    }
  }
  return changedOrders;
};

export const getLast30Days = (): string[] => {
  const today = new Date();
  const last30Days = [];
  for (let i = 0; i < 30; i += 3) {
    const date = new Date(today.getFullYear(), today.getMonth(), today.getDate() - i);
    last30Days.push(date.toISOString());
  }

  return last30Days.reverse();
};

export const getTodaysISOTime = (): number => {
  const today = new Date();
  return today.getTime();
};

export const getFullLast30Days = (): string[] => {
  const today = new Date();
  const last30Days = [];
  for (let i = 0; i < 30; i += 1) {
    const date = new Date(today.getFullYear(), today.getMonth(), today.getDate() - i);
    last30Days.push(date.toISOString());
  }

  return last30Days.reverse();
};

export const getLast90Days = (): string[] => {
  const today = new Date();
  const last90Days = [];
  for (let i = 0; i < 90; i += 3) {
    const date = new Date(today.getFullYear(), today.getMonth(), today.getDate() - i);
    last90Days.push(date.toISOString());
  }

  return last90Days.reverse();
};
export const getLast180Days = (): string[] => {
  const today = new Date();
  const last180Days = [];
  for (let i = 0; i < 180; i += 3) {
    const date = new Date(today.getFullYear(), today.getMonth(), today.getDate() - i);
    last180Days.push(date.toISOString());
  }

  return last180Days.reverse();
};

// random data in 30 days
export const getRandomData = (len = 30): number[] => {
  const step = len === 30 ? 3 : 1;
  const data = [];

  for (let i = 0; i < len; i += step) {
    data.push(Math.floor(Math.random() * 30));
  }
  return data;
};

export const getFullImagePath = (url: string): string => {
  if (url) {
    return `${S3_BUCKET_URL}${url}`;
  }
  return null;
};

type TempItem = { createdAt: number };
export const sortingDesToAsc = (a: TempItem, b: TempItem): number => {
  return a.createdAt - b.createdAt;
};

export const titleCase = (str: string) => {
  return str[0].toUpperCase() + str.slice(1).toLowerCase();
};

export const createCopy = obj => {
  return JSON.parse(JSON.stringify(obj));
};

export const getReferenceId = (cbId, allBlocks, currentBlock) => {
  const blocks = allBlocks.filter(cb => cb.cbId === cbId);
  if (blocks.length > 0) {
    return blocks[0].basicInfo.referenceId;
  }
  if (currentBlock.cbId === cbId) {
    return currentBlock.basicInfo.referenceId;
  }
  return cbId;
};

export const getDisplayLogic = (cbId, contentBlocks, currentBlock) => {
  const cb = contentBlocks.filter(cblock => cblock.cbId === cbId)[0];
  if (cb && cb.rawDisplayLogic) {
    return cb.rawDisplayLogic;
  }
  if (cb && cb.displayLogics && cb.displayLogics.length > 0) {
    const { ruleAggregator } = cb;
    let logicString = '';
    cb.displayLogics.forEach((logic, index) => {
      if (logic.type === 'R') {
        logicString += `Response of Block  ${getReferenceId(logic.key, contentBlocks, currentBlock)}  `;
      } else {
        logicString += `Profile Element  ${logic.key} `;
      }
      logicString += `${logic.rule}  ${logic.value}`;
      if (index !== cb.displayLogics.length - 1) {
        logicString += ` ${ruleAggregator} `;
      }
    });
    return logicString;
  }
  return 'Always Show';
};

export const toDateString = (date: string) => (date ? dayjs(date).format(GLOBAL_DATE_FORMAT) : '');

export const diffDays = (day1: Date | string, day2: Date | string) => {
  const date1 = new Date(day1);
  const date2 = new Date(day2);
  const diffTime = Math.abs(date2.getTime() - date1.getTime());
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
};

export const sharedGooglePlayload = (
  authToken: string,
  calenderExternalId: string,
  email: string,
  name: string,
  expiry: string | number,
  lastSyncAt: string,
  syncConfidantSystemToGoogle: boolean,
  syncGoogleToConfidantSystem: boolean,
  visibilityTag: string,
  status: boolean,
  type: string,
) => {
  const data: IGoogleCalender = {
    authToken: authToken ?? '',
    calenderExternalId: calenderExternalId ?? '',
    email: email ?? '',
    name: name ?? '',
    expiry: expiry ?? '',
    lastSyncAt: lastSyncAt ?? null,
    calendarSettings: {
      syncConfidantSystemToGoogle: syncConfidantSystemToGoogle ?? true,
      syncGoogleToConfidantSystem: syncGoogleToConfidantSystem ?? true,
      visibilityTag: visibilityTag ?? '',
    },
    status: status ?? false,
    tings: {
      syncConfidantSystemToGoogle: syncConfidantSystemToGoogle ?? true,
      syncGoogleToConfidantSystem: syncGoogleToConfidantSystem ?? true,
      visibilityTag: visibilityTag ?? '',
    },
    type: type ?? '',
  };
  return data;
};

export const getDSTOffset = () => {
  return moment({ M: 1, d: 1 }).utcOffset() / 60;
};

export const getTimeByDSTOffset = (time: string) => {
  const dstOffset = getDSTOffset();
  return moment(time).utcOffset(dstOffset);
};

const formatDSTTimeStamp = date => {
  return date?.format('YYYY-MM-DDTHH:mm:ss.sssZ');
};
export const getDSTOffsetDetails = (appointmentStartTime: string, appointmentEndTime: string) => {
  let dstStartTimeStamp = '';
  let dstEndTimeStamp = '';
  if (!appointmentStartTime || !appointmentEndTime) {
    return { appointmentStartTime, appointmentEndTime };
  }
  const dateAfterDSTOffset = getTimeByDSTOffset(appointmentStartTime).utcOffset();
  const dateBeforeDSTOffset = dayjs(appointmentStartTime).utcOffset();
  if (dateAfterDSTOffset === dateBeforeDSTOffset) {
    dstStartTimeStamp = formatDSTTimeStamp(dayjs(appointmentStartTime));
    dstEndTimeStamp = formatDSTTimeStamp(dayjs(appointmentEndTime));
  } else if (dateAfterDSTOffset < dateBeforeDSTOffset) {
    dstStartTimeStamp = formatDSTTimeStamp(dayjs(appointmentStartTime).subtract(1, 'hours'));
    dstEndTimeStamp = formatDSTTimeStamp(dayjs(appointmentEndTime).subtract(1, 'hours'));
  } else {
    dstStartTimeStamp = formatDSTTimeStamp(dayjs(appointmentStartTime).add(1, 'hours'));
    dstEndTimeStamp = formatDSTTimeStamp(dayjs(appointmentEndTime).add(1, 'hours'));
  }
  return { dstStartTimeStamp, dstEndTimeStamp };
};

export const getDSTOffSetTime = (timeStamp: string) => {
  return timeStamp; // truning all the dst adjustments
  /* let dstOffSetTime = '';
  if (!timeStamp) {
    return timeStamp;
  }
  const dateAfterDSTOffset = getTimeByDSTOffset(timeStamp).utcOffset();
  const dateBeforeDSTOffset = dayjs(timeStamp).utcOffset();
  if (dateAfterDSTOffset === dateBeforeDSTOffset) {
    dstOffSetTime = formatDSTTimeStamp(dayjs(timeStamp));
  } else if (dateAfterDSTOffset < dateBeforeDSTOffset) {
    dstOffSetTime = formatDSTTimeStamp(dayjs(timeStamp).subtract(1, 'hours'));
  } else {
    dstOffSetTime = formatDSTTimeStamp(dayjs(timeStamp).add(1, 'hours'));
  }
  return dstOffSetTime; */
};

export const makeArrayUnique = (array, key) => {
  if (!Array.isArray(array)) {
    throw new Error('makeArrayUnique: The first argument must be an array.');
  }

  return array.reduce((accumulator, currentValue) => {
    // Check if the object with the same key already exists in the accumulator
    const isDuplicate = accumulator.some(item => item[key] === currentValue[key]);

    if (!isDuplicate) {
      // If it's not a duplicate, add it to the accumulator
      accumulator.push(currentValue);
    }

    return accumulator;
  }, []);
};

export const uniqueSBChannels = channels => {
  return Array.from(new Map(channels.map(channel => [channel.url, channel])).values());
};

export const uniqueConnections = (connections: any[]): any[] => {
  return Array.from(new Map(connections.map(conn => [conn.connectionId, conn])).values());
};

const isRelatedTo = (key, item) => {
  return item.tagMetaData.specification[key] && item.tagMetaData.specification[key].length > 0;
};

const getRelatedToText = item => {
  let subText = null;
  if (item.tagMetaData && item.tagMetaData.specification) {
    if (isRelatedTo('relatedToMedicalCondition', item)) {
      subText = 'Related To Medical Condition';
    }
    if (isRelatedTo('relatedToMedication', item)) {
      subText = 'Related To Medication';
    }
    if (isRelatedTo('relatedToSubstanceUse', item)) {
      subText = 'Related To Substance Use';
    }
    if (isRelatedTo('relatedToWithdrawal', item)) {
      subText = 'Related To Withdrawal';
    }
  }
  return subText;
};

const getMedication = (item: IDomainElementDetail | IDomainElement): string => {
  const {
    tagMetaData: { rxDrugInfo },
  } = item;
  return `${rxDrugInfo.dose} ${(rxDrugInfo.doseUnit || '').toLowerCase()}
      ${
        frequencyConstants[rxDrugInfo.doseFrequency] || rxDrugInfo.doseFrequency > 0
          ? `${rxDrugInfo.doseFrequency} times`
          : rxDrugInfo.frequency
      }
      ${rxDrugInfo.supplyUnit ? dayConstants[rxDrugInfo.supplyUnit] : ''}`;
};

const getSubstanceDescription = (item: IDomainElementDetail | IDomainElement, lookupData: HistoryLookup) => {
  const {
    tagMetaData: { substanceUse },
  } = item;
  const method = lookupData.methodsOfSubstanceUse?.find(opt => opt.name === substanceUse.methodOfUse);
  const frequency = lookupData.currentFrequencyOfSubstanceUse?.find(
    opt => opt.name === substanceUse.currentFrequencyOfUse,
  );
  const duration = lookupData.continuousLevelOfSubstanceUse?.find(
    opt => opt.name === substanceUse.howLongUsingThisLevel,
  );
  return `${method?.value || ''}
      ${frequency?.value?.toLowerCase() || ''} for a ${duration?.value?.toLowerCase() || 'days'}`;
};

export const getDomainElementDescription = (item: any, lookup: HistoryLookup): string => {
  switch (item.domainTypeName) {
    case DomainTypesEnum.SYMPTOMS:
    case DomainTypesEnum.DIAGNOSES:
      return getRelatedToText(item);
    case DomainTypesEnum.MEDICATIONS:
      return getMedication(item);
    case DomainTypesEnum.SUBSTANCE_USE:
      return getSubstanceDescription(item, lookup);
    default:
      return '';
  }
};

export const mapToLegacy = (record: any, lookupData: HistoryLookup): any => {
  return {
    ...record,
    title: record.taxonomyName,
    date: dayjs(record.assignedAt).format('MM/DD/YYYY'),
    name: record.taxonomyName,
    provider: {
      fullName: record.assignedBy?.name || record.userDetails?.name,
      role: record.assignedBy?.designation || record.userDetails?.designation,
      profileImage: record.assignedBy?.profileImage,
    },
    description: getDomainElementDescription(record, lookupData),
    avatar: record.assignedBy?.profileImage,
    priority: { name: record.currentImportanceLevel },
    assignedBy: record.assignedBy?.name || record.userDetails?.name,
    notes: record.note,
    history:
      record.history?.map(historyItem =>
        mapToLegacy(
          {
            ...historyItem,
            taxonomyName: record.taxonomyName,
            currentImportanceLevel: historyItem.importanceLevel,
            importanceLevel: { name: historyItem.importanceLevel },
          },
          lookupData,
        ),
      ) || [],
    icd: record.selectedIct10code,
    dosage: record.tagMetaData?.rxDrugInfo?.dosage,
    frequency: record.tagMetaData?.rxDrugInfo?.frequency,
    createdAt: dayjs(record.assignedAt).format('MM/DD/YYYY'),
    assignedAt: record.assignedAt,
    status: record.currentImportanceLevel,
    duration: record.tagMetaData?.rxDrugInfo?.duration,
  };
};

export const collapseObject = (obj, currentString = '') => {
  let results = {};
  if (obj) {
    Object.entries(obj).forEach(([key, value]) => {
      if (typeof value === 'object') {
        let object;
        if (currentString !== '') {
          object = collapseObject(value, `${currentString}_${key}`);
        } else {
          object = collapseObject(value, key);
        }
        results = Object.assign(results, object);
      } else if (currentString !== '') {
        results[`${currentString}_${key}`] = value;
      } else results[key] = value;
    });
  }
  return results;
};

export const toSentenceCase = (str: string) => {
  return str?.charAt(0)?.toUpperCase() + str?.slice(1)?.toLowerCase();
};

export const initiateVideoSession = async (channelUrl, isDirectCall) => {
  try {
    const res = await startOrJoinCall(
      {
        channelUrl,
      },
      { isDirectCall },
    );
    const session = OT.initSession(OPENTOK_APIKEY, res?.data?.sessionId);
    return {
      call: res.data,
      session,
    };
  } catch (error) {
    console.log({ error });
    return null;
  }
};

export const isUserOnline = (onlineUsers, cId) => {
  const status = onlineUsers?.includes(cId);
  return !!status;
};

export const combineMessages = (messages, timeThreshold = 60000) => {
  return messages.reduce((combinedMessages, message) => {
    const lastMessage = combinedMessages[combinedMessages.length - 1];

    if (
      lastMessage &&
      lastMessage.user.userId === message.user.userId &&
      message.createdAt - lastMessage.createdAt <= timeThreshold
    ) {
      lastMessage.message.text += `\n${message.message.text}`;
      lastMessage.createdAt = message.createdAt;
    } else {
      combinedMessages.push({ ...message });
    }

    return combinedMessages;
  }, []);
};

export const mergeConsecutiveMessages = messageList => {
  if (messageList.length === 0) return [];

  const mergedMessages = [];
  let previousMessage = { ...messageList[0] };

  // Iterate through the messages starting from the second one
  let index = 1;
  while (index < messageList.length) {
    const currentMessage = messageList[index];

    // If the sender is the same, merge the content
    if (currentMessage.user.userId === previousMessage.user.userId) {
      previousMessage.message.text = `${previousMessage.message.text}<br/>${currentMessage.message.text}`;
    } else {
      // If different sender, push the current message to the mergedMessages array
      mergedMessages.push(previousMessage);
      previousMessage = { ...currentMessage };
    }

    index++;
  }

  // Add the last merged message to the result
  mergedMessages.push(previousMessage);
  return mergedMessages;
};
