import { useEffect, useState, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import clsx from 'clsx';
import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { fontWeight, Text } from '@confidant-health/lib/ui/atoms/typography';
import { Button, btnType } from '@confidant-health/lib/ui/atoms/button';
import { Stepper } from '@confidant-health/lib/ui/molecules/stepper';
import { Icons } from '@confidant-health/lib/icons';
import { colors } from '@confidant-health/lib/colors';
import { Drawer, drawerType } from '@confidant-health/lib/ui/organisms/drawer';
import dayjs from 'utils/dayjs';
import { getProfile } from 'redux/modules/profile/selectors';
import { getAppointment } from 'redux/modules/appointment/selectors';
import { profileActionCreators } from 'redux/modules/profile/actions';
import { appointmentActionCreators } from 'redux/modules/appointment';
import * as memberService from 'services/member/member.service';
import StepOne from 'pages/admin/appointments/add-schedule/step-one/StepOne';
import StepThree from 'pages/admin/appointments/add-schedule/step-three/StepThree';
import { INewSchedulePayload } from 'pages/admin/appointments/add-schedule/AddSchedule.types';
import {
  StepTitles,
  ScheduleSteps,
  StepsNumber,
} from 'pages/admin/appointments/add-schedule/AddSchedule.constants';
import { useStyles } from 'pages/admin/appointments/add-schedule/AddSchedule.styles';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  memberId: string;
  providerId: string;
  serviceId: string;
  startTime: string;
  memberConnection: any;
};

const ScheduleAppointment: FC<Props> = ({
  isOpen,
  onClose,
  memberId,
  providerId,
  serviceId,
  startTime,
  memberConnection,
}) => {
  const classes = useStyles();

  const {
    profile,
    payload: { patientsList },
    providers,
    isLoading,
  } = useSelector(getProfile);
  const { isRequesting } = useSelector(getAppointment);
  const dispatch = useDispatch();

  const [currentStep, setCurrentStep] = useState(ScheduleSteps.SELECT_MEMBER);
  const [errorMsg, setErrorMsg] = useState(null);
  const [memberProfile, setMemberProfile] = useState({} as any);
  const [memberList, setMemberList] = useState(patientsList);
  const [loader, setLoader] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchKey, setSearchKey] = useState('');
  const [formData, setFormData] = useState({
    member: null,
    provider: null,
    time: null,
    serviceId: null,
  });

  useEffect(() => {
    if (memberId) {
      setLoader(true);
      memberService
        .getAdminProfile({ userId: memberId || '' })
        .then(res => {
          setMemberProfile(res.data);
          setLoader(false);
        })
        .catch(err => {
          setLoader(false);
          console.log(err);
        });
    }
    fetchMembers('');
    fetchProviders();
  }, []);

  useEffect(() => {
    if (memberConnection) {
      let memberInSession = patientsList.find(item => item.member.id === memberId);
      const membersList = patientsList.filter(item => item.member.id !== memberId);

      if (memberInSession) {
        memberInSession.id = memberId;
      } else {
        memberInSession = {
          dob: '',
          email: memberConnection.email,
          firstName: '',
          fullName: memberConnection.fullName,
          fundsInWallet: 0,
          lastName: '',
          phone: memberConnection.phone,
          totalCompletedSessions: 0,
          completed: 0,
          id: memberId,
          member: {
            photo: memberConnection.profilePicture,
            id: memberId,
            nickName: memberConnection.nickName,
            firstName: memberConnection.firstName,
            lastName: memberConnection.lastName,
          },
        };
      }
      membersList.unshift(memberInSession);
      // setMembers(membersList);
      setFormData({ ...formData, member: memberInSession });
    }
  }, [patientsList]);

  useEffect(() => {
    setFormData({
      ...formData,
      provider: providers.find(item => item.providerId === providerId) || providers[0],
    });
  }, [providers]);

  useEffect(() => {
    const fetchPatients = async () => {
      if (pageNumber !== 1) {
        setLoadMore(true);
        const response = await memberService.getPatientsList(
          {
            searchQuery: searchKey,
            pageNumber,
            pageSize: 10,
            orderBy: 'asc',
            sortBy: '',
            // patientId: memberId || '',
          },
          null,
        );
        if (response.status === 200) {
          const { data } = response;

          const updatedPatientsList = data.patientsList.map(member => {
            return {
              member: {
                id: member.userId,
                nickName: member.fullName,
                fullName: member.fullName,
                firstName: member.firstName,
                lastName: member.lastName,
                photo: '',
              },
              email: member.email,
              phone: member.phoneNumber,
              activeChat: member.activeChat || false,
              completed: member.totalCompletedSessions,
              fundsInWallet: member.fundsInWallet,
              dob: member.dob ? dayjs(member.dob, 'YYYY-MM-DD').format('MM/DD/YYYY') : null,
              actions: {
                id: member.userId,
              },
            };
          });
          setLoadMore(false);

          setMemberList([...memberList, ...updatedPatientsList]);
        }
      }
    };
    fetchPatients().catch(console.error);
  }, [pageNumber]);

  const onCloseClick = () => {
    if (currentStep === ScheduleSteps.SELECT_MEMBER) {
      onClose();
    } else {
      const prevStep =
        currentStep === ScheduleSteps.SELECT_SERVICE
          ? ScheduleSteps.SELECT_PROVIDER
          : ScheduleSteps.SELECT_MEMBER;
      setCurrentStep(prevStep);
    }
  };

  const onChangeFormData = key => payload => {
    errorMsg && setErrorMsg(null);
    setFormData({ ...formData, [key]: payload });
  };

  const fetchMembers = (searchQuery = '') => {
    setLoader(true);
    dispatch(
      profileActionCreators.fetchPatients({
        searchQuery,
        pageNumber: pageNumber || 1,
        pageSize: 10,
        orderBy: 'asc',
        sortBy: '',
        patientId: memberId || '',
      }),
    );
    if (memberId && !searchQuery) {
      memberService
        .getAdminProfile({ userId: memberId || '' })
        .then(res => {
          setMemberProfile(res.data);
        })
        .catch(err => {
          console.log(err);
        });
    }
  };

  const fetchProviders = (searchQuery = '') => {
    dispatch(
      profileActionCreators.fetchProviders({
        searchQuery,
        pageNumber: 1,
        pageSize: 1000,
        orderBy: 'asc',
        sortBy: '',
      }),
    );
  };

  useEffect(() => {
    if (memberId) {
      setMemberList([
        {
          ...memberProfile,
          id: memberId,
          nickName: memberProfile?.firstName || '',
          phone: memberProfile?.phoneNumber || '',
          email: memberProfile?.emailAddress || '',
          photo: memberProfile?.profileImage || '',
          fullName: `${memberProfile?.firstName || ''} ${memberProfile?.lastName || ''}`,
          uuid: memberProfile?.uuid,
        },
        ...patientsList.filter(item => item.id !== memberId),
      ]);
    } else {
      setMemberList(patientsList);
    }
  }, [memberProfile, patientsList]);

  const createAppointmentForSelf = (payload: INewSchedulePayload) => {
    dispatch(
      appointmentActionCreators.createAppointment({
        data: payload,
        callback: (isSucceed: boolean) => {
          if (isSucceed) {
            onClose();
          }
        },
      }),
    );
  };

  const createAppointmentForOtherProvider = (payload: INewSchedulePayload) => {
    dispatch(
      appointmentActionCreators.createAppointment({
        data: payload,
        callback: (isSucceed: boolean) => {
          if (isSucceed) {
            onClose();
          }
        },
      }),
    );
  };

  const onSubmitNewSchedule = (payload: INewSchedulePayload) => {
    if (profile.providerId === payload.provider.id) {
      createAppointmentForSelf(payload);
    } else {
      createAppointmentForOtherProvider(payload);
    }
  };

  const onClickContinue = () => {
    if (currentStep === ScheduleSteps.SELECT_MEMBER) {
      if (!formData.member) {
        setErrorMsg('Please select a member!');
        return;
      }
      fetchMembers('');
    }
    if (currentStep === ScheduleSteps.SELECT_PROVIDER && !formData.provider) {
      setErrorMsg('Please select a provider!');
      return;
    }
    if (currentStep === ScheduleSteps.SELECT_SERVICE && !formData.serviceId) {
      setErrorMsg('Please select a service!');
      return;
    }
    if (currentStep === ScheduleSteps.SELECT_SERVICE && !formData.time) {
      setErrorMsg('Please select a time slot!');
      return;
    }

    if (currentStep !== ScheduleSteps.SELECT_SERVICE) {
      setCurrentStep(
        currentStep === ScheduleSteps.SELECT_MEMBER
          ? ScheduleSteps.SELECT_PROVIDER
          : ScheduleSteps.SELECT_SERVICE,
      );
    } else {
      onSubmitNewSchedule(formData);
    }
  };

  const renderStep = step => {
    switch (step) {
      case ScheduleSteps.SELECT_MEMBER:
        return (
          <StepOne
            error={errorMsg}
            selectedItem={formData.member}
            fetchMembers={fetchMembers}
            onChange={onChangeFormData('member')}
            options={memberList.map(({ member, ...rest }) => ({ ...rest, ...member }))}
            selectType="member"
            userId={memberId}
            setPageNumber={setPageNumber}
            isLoading={loadMore}
            pageNumber={pageNumber}
            setSearchKeyWithPagination={setSearchKey}
          />
        );
      case ScheduleSteps.SELECT_PROVIDER:
        return (
          <StepOne
            error={errorMsg}
            selectedItem={formData.provider}
            onChange={onChangeFormData('provider')}
            options={providers}
            selectType="provider"
            userId={providerId}
          />
        );
      default:
        return (
          <StepThree
            error={errorMsg}
            formData={formData}
            selectedDateTime={startTime}
            onChange={onChangeFormData('time')}
            onChangeService={onChangeFormData('serviceId')}
            serviceId={serviceId}
          />
        );
    }
  };

  const saveText = isRequesting ? 'Saving...' : 'Schedule appointment';
  const showFooter =
    (currentStep === ScheduleSteps.SELECT_MEMBER && !!formData.member) ||
    (currentStep === ScheduleSteps.SELECT_PROVIDER && !!formData.provider) ||
    currentStep === ScheduleSteps.SELECT_SERVICE;

  return (
    <Drawer open={isOpen} onClose={onCloseClick} variant={drawerType.NORMAL} className={classes.drawer}>
      <Box className={classes.wrapper}>
        <Box className={classes.header}>
          <Box>
            <Text weight={fontWeight.BOLD} className={classes.headTitle}>
              Schedule next appointment
            </Text>
            <Text weight={fontWeight.BOLD} className={classes.headSubtitle}>
              {StepTitles[currentStep]}
            </Text>
          </Box>
          <Box className={classes.stepWrap}>
            <Stepper steps={['Member', 'Provider', 'Date & time']} activeStep={StepsNumber[currentStep]} />
          </Box>
          <IconButton icon="close" className={classes.closeBtn} onClick={onClose} />
        </Box>
        <Box
          className={clsx(classes.content, {
            [classes.showFooter]: showFooter,
          })}
        >
          {loader && isLoading ? (
            <div className={classes.loader}>
              <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
              Loading...
            </div>
          ) : (
            renderStep(currentStep)
          )}
        </Box>
        {showFooter && (
          <Box className={classes.footer}>
            <Button variant={btnType.TEXT} onClick={onCloseClick}>
              Cancel
            </Button>
            <Button onClick={onClickContinue} disabled={isRequesting}>
              {currentStep === ScheduleSteps.SELECT_SERVICE ? saveText : 'Continue'}
            </Button>
          </Box>
        )}
      </Box>
    </Drawer>
  );
};

export { ScheduleAppointment };
