import { AxiosResponse } from 'axios';
import { baseRequest } from 'utils/HttpClient';
import { ApiEndpoints } from 'constants/ApiEndpoints';

type IAppointmentFilterOption = {
  searchField:
    | 'participantName'
    | 'providerName'
    | 'paymentMethod'
    | 'serviceType'
    | 'startTime'
    | 'endTime'
    | 'status'
    | 'serviceCost'
    | 'serviceDuration'
    | 'amountPaid';
  searchQuery: string;
};

type IGetAppointmentsBodyRequest = {
  providerId: string;
  type: string;
  timezone: string;
  refDate?: string;
  size?: number;
  startDate?: string;
  endDate?: string;
  textSearch: string;
  statuses: 'PROPOSED' | 'PENDING' | 'BOOKED' | 'CANCELLED' | 'FULFILLED' | 'NO_SHOW'[];
  filters: IAppointmentFilterOption[];
};

type IGetAppointmentsQueryParams = {
  orderBy?: string;
  pageNumber?: number;
  pageSize?: number;
  sortBy?:
    | 'participantName'
    | 'providerName'
    | 'serviceType'
    | 'serviceType'
    | 'startTime'
    | 'endTime'
    | 'status';
};

export const getAppointments = (
  bodyRequest: IGetAppointmentsBodyRequest,
  queryParams: IGetAppointmentsQueryParams,
): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.GET_APPOINTMENTS_V4, bodyRequest, null, queryParams);
};
export const downloadAppointments = (payload: any, requestBody?: any): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.GET_APPOINTMENTS_V4, requestBody || {}, null, payload, true, true, true);
};
export const getAppointmentCptCodeById = (id: string): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.GET_APPOINTMENT_CPT_CODE_BY_ID, null, { id });
};

type IAddAppointmentCptCodeBodyRequest = {
  appointmentId: string;
  cptList: {
    cptCode: string;
    diagnosis: string;
    icd10Code: string;
    notes: string;
  }[];
};
export const addAppointmentCptCode = (
  bodyRequest: IAddAppointmentCptCodeBodyRequest,
): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.ADD_APPOINTMENT_CPT_CODE, bodyRequest);
};

export const updateAppointmentCptCode = (
  bodyRequest: IAddAppointmentCptCodeBodyRequest,
  pathParams: { id: string },
): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.UPDATE_APPOINTMENT_CPT_CODE, bodyRequest, pathParams);
};
