import { FC } from 'react';
import cx from 'clsx';
import { Box } from '@mui/material';

import { Icons } from '@confidant-health/lib/icons';
import dayjs from 'dayjs';
import { getUserTimeZone } from 'utils/dayjs';
import { SideUserDataT } from './Chats.types';
import { useStyles } from './Chats.styles';
import { CONNECTIONS_TYPES } from '../../../constants/CommonConstants';

type Props = {
  isActive: boolean;
  item: SideUserDataT;
  onlineUsers?: string[];
  selectContact: () => void;
  getDesignation: (designation: string) => JSX.Element;
};

const SideUserCard: FC<Props> = ({ isActive, selectContact, item, onlineUsers, getDesignation }) => {
  const classes = useStyles({ isCareTem: item.type === CONNECTIONS_TYPES.CARE_TEAM });

  const getSelectedContactFullName = () => {
    if (item.type === CONNECTIONS_TYPES.PATIENT || item.type === CONNECTIONS_TYPES.CARE_TEAM) {
      if (item?.firstName && item?.lastName) return `${item?.firstName} ${item?.lastName}`;
    }
    return item?.fullName ?? item?.name;
  };

  return (
    <Box
      onClick={selectContact}
      className={cx({
        [classes.userContactCard]: true,
        isActive,
      })}
    >
      <Box className={classes.sideCardWrapper}>
        <Box className={classes.sideCardHeader}>
          <Box className={classes.headerNameText}>
            <span>{getSelectedContactFullName()}</span>
            {(item?.type === 'PATIENT' || item?.type === 'CARE_TEAM') && <span>{`• ${item.uuid}`}</span>}
          </Box>
          <Box>{getDesignation(item?.type)}</Box>
        </Box>
        <Box className={classes.sideCardMessage}>{item?.lastMessage || 'No message yet'}</Box>
        {item?.lastMessage && (
          <Box className={classes.sideCardFooter}>
            <Box display="flex" gap="4px" alignItems="center">
              <Icons
                glyph={item?.unreadMessageCount ? 'message-unread-outlined' : 'checkmark'}
                color={item?.unreadMessageCount ? '#DD0374' : '#2DA834'}
              />
              <Box className={classes.sideCardRead} color={item?.unreadMessageCount ? '#DD0374' : '#2DA834'}>
                {item?.unreadMessageCount ? 'Unread' : 'Read'}
              </Box>
            </Box>
            <Box className={classes.sideCardTime}>
              {dayjs(item?.lastMessageTimestampUnix).tz(getUserTimeZone()).format('MMM D [at] h:mmA')}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default SideUserCard;
