import React, { FC, useEffect } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import { Icons } from '@confidant-health/lib/icons';
import { useSelector } from 'react-redux';
import { getAuth } from 'redux/modules/auth/selectors';
import { colors } from '@confidant-health/lib/colors';
import { useStyles } from './ChatsMenu.style';

type Props = {
  onOpenInstantAppointment: () => void;
  openProfile: () => void;
  openVisitFrequencyDrawer: () => void;
  onOpenNotes: () => void;
  // onClose: () => void;
  openIsCreateConnection: () => void;
  isAdmin?: boolean;
};

const ChatsMenu: FC<Props> = ({
  onOpenInstantAppointment,
  openProfile,
  onOpenNotes,
  openVisitFrequencyDrawer,
  // onClose,
  openIsCreateConnection,
  isAdmin,
}) => {
  const classes = useStyles();
  const { meta } = useSelector(getAuth);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button variant="outlined" onClick={handleClick} className={classes.menuButtonHeader}>
        <Icons glyph="more" color={colors.primary500} />
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          className={classes.menuItem}
          onClick={() => {
            // onClose();
            openProfile();
          }}
        >
          <Icons glyph="user" className={classes.calendarBtn} />
          <span className={classes.menuText}>Go to profile</span>
        </MenuItem>
        <MenuItem
          className={classes.menuItem}
          onClick={() => {
            handleClose();
            onOpenInstantAppointment();
          }}
        >
          <Icons glyph="calendar-today-outlined" className={classes.calendarBtn} />
          <span className={classes.menuText}>Schedule appointment</span>
        </MenuItem>
        <MenuItem
          className={classes.menuItem}
          onClick={() => {
            handleClose();
            onOpenNotes();
          }}
        >
          <Icons glyph="file-list-line-fold-outlined" className={classes.calendarBtn} />
          <span className={classes.menuText}>Create a new note</span>
        </MenuItem>
        {!isAdmin && (
          <MenuItem
            className={classes.menuItem}
            onClick={() => {
              handleClose();
              openVisitFrequencyDrawer();
            }}
          >
            <Icons glyph="edit-underline" className={classes.calendarBtn} />
            <span className={classes.menuText}>Update Visit Frequency</span>
          </MenuItem>
        )}
        {(meta.authority === 'BILLING_SUPPORT' || meta.authority === 'ADMIN') && (
          <MenuItem
            className={classes.menuItem}
            onClick={() => {
              handleClose();
              openIsCreateConnection();
            }}
          >
            <Icons glyph="conversations-filled" className={classes.calendarBtn} />
            <span className={classes.menuText}>Delete connection</span>
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export { ChatsMenu };
