import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Menu, MenuItem, Tooltip } from '@mui/material';
import { Icons } from '@confidant-health/lib/icons';
import { conversationActionCreators } from 'redux/modules/conversation';
import { useStyles } from './ChatsMenu.style';

type Props = {
  id?: number;
};

const ChatThreadMenu: FC<Props> = ({ id }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    // onClose();
    setAnchorEl(null);
  };
  return (
    <>
      <Tooltip title="More">
        <Button variant="outlined" onClick={handleClick} className={classes.menuButtonThread}>
          <Icons glyph="more" color="#8EC8FD" className={classes.menuIcons} />
        </Button>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          className={classes.menuItem}
          onClick={() => {
            dispatch(conversationActionCreators.addRecentMessage({ id }));
            handleClose();
          }}
        >
          <Icons glyph="appointment" className={classes.calendarBtn} />
          <span className={classes.menuText}>Add due date</span>
        </MenuItem>
        {/* <MenuItem
          className={classes.menuItem}
          onClick={() => {
            handleClose();
          }}
        >
          <Icons glyph="outlined-bell" className={classes.calendarBtn} />
          <span className={classes.menuText}>Notify via sms</span>
        </MenuItem> */}
      </Menu>
    </>
  );
};

export { ChatThreadMenu };
