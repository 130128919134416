import { makeStyles } from '@mui/styles';

// colors
import { colors } from '@confidant-health/lib/colors';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    height: '100vh',
    overflow: 'hidden',
  },
  flex: {
    display: 'flex',
    flexDirection: 'column',
  },
  between: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  rightContainer: {
    position: 'relative',
    backgroundColor: colors.primary50,
    '& .slick-list': {
      height: '100vh !important',
    },
  },
  loginBackground: {
    width: '75%',
    height: '60%',
    objectFit: 'contain',
  },
  form: { display: 'flex', flexDirection: 'column', gap: 16 },
  outlineBtn: {
    height: 'unset',
    padding: '10px 16px',
    fontSize: 14,
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  input: {
    width: 448,
    height: 64,
    paddingLeft: 16,
  },
  eye: {
    cursor: 'pointer',
  },
  buttonContainer: {
    width: '100%',
    marginTop: 16,
    height: 64,
    '&.Mui-disabled': {
      background: colors.primary50,
      color: colors.primary300,
    },
  },
  errorText: {
    color: colors.textError,
    marginBottom: 0,
  },
  buttonTextContainer: {
    width: '100%',
  },
  bottomContainer: {
    position: 'absolute',
    bottom: 40,
    display: 'flex',
  },
  card: {
    background: colors.primary100,
    minHeight: 148,
    marginBottom: 24,
    padding: 24,
    display: 'flex !important',
    gap: 24,
    borderRadius: 12,
  },
  startBackground: {
    position: 'absolute',
    top: 0,
    left: 0,
    background: 'linear-gradient(180deg, #F1F8FF 0%, rgba(241, 248, 255, 0) 100%)',
    width: '100%',
    height: 160,
    zIndex: 10,
  },
  endBackground: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    background: 'linear-gradient(180deg, rgba(241, 248, 255, 0) 0%, #F1F8FF 100%)',
    width: '100%',
    height: 160,
    zIndex: 10,
  },
  errorMsg: {
    display: 'flex',
    gap: 16,
    background: colors.destructive50,
    borderRadius: 8,
    padding: 16,
    alignItems: 'center',
  },
  errorIcon: {
    minHeight: 24,
    minWidth: 24,
  },
});
