import React, { FC } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import { Icons } from '@confidant-health/lib/icons';
import { useStyles } from './ChatsMenu.style';

type Props = {
  onOpen: () => void;
  onClose: () => void;
  isAdmin?: boolean;
  options: {
    label: string;
    value: string;
    count: number;
  }[];
  onChange: (target: any) => void;
};

const SearchMenu: FC<Props> = ({ onClose, isAdmin, options, onChange }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    onClose();
    setAnchorEl(null);
  };
  return (
    <>
      <Button variant="text" onClick={handleClick} className={classes.menuButtonHeader}>
        <Icons glyph="filter-outlined" color="#A4B4C3" className={classes.menuIcon} />
      </Button>
      <Menu
        style={{ marginTop: 35 }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {options?.map((item, index) => (
          <MenuItem
            key={index}
            className={classes.menuItem}
            onClick={() => {
              handleClose();
              onChange(item);
            }}
          >
            <span className={classes.menuText}>{item.label}</span>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export { SearchMenu };
