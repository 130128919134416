import { FC, useCallback, useEffect, useState } from 'react';
import { Box, Stack } from '@mui/material';
import clsx from 'clsx';
import { Filter, Table, tableParamsType } from '@confidant-health/lib/ui/organisms/table';
import { fontWeight, Heading, headingLevel, Text } from '@confidant-health/lib/ui/atoms/typography';
import { Badge, badgeStyle, badgeType } from '@confidant-health/lib/ui/atoms/badge';
import { btnType } from '@confidant-health/lib/ui/atoms/button';
import { colors } from '@confidant-health/lib/colors';
import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { Menu } from '@confidant-health/lib/ui/molecules/menu';
import { ProfileInfo } from '@confidant-health/lib/ui/templates/profile-info';
import { Checkbox } from '@confidant-health/lib/ui/atoms/checkbox';
import { saveAs } from 'file-saver';
import { useDispatch, useSelector } from 'react-redux';
import { ConfirmationModal } from '@confidant-health/lib/ui/templates/confirmation-modal';
import { DROPDOWN_FILTER_COLLAPSABLE_TYPE } from '@confidant-health/lib/ui/organisms/table/filter';

import { showSnackbar } from 'redux/modules/snackbar';
import { IInvoiceRecord } from 'redux/modules/reporting/types';
import { BaseLayout } from 'layouts/base';
import { profileActionCreators } from 'redux/modules/profile';
import { AppState } from 'redux/store/types';
import dayjs from 'utils/dayjs';
import { Icons } from '@confidant-health/lib/icons';

import ProviderFeeDetailDrawer from './provider-fee-detail-drawer';
import { multiSelectFilterOptionsMock, tableColumns } from './ProviderFee.constants';
import { useStyles } from './ProviderFee.styles';
import { IProviderFee } from './ProviderFee.mock';
import {
  getProviderFeeList,
  downloadProviderFeeList,
  markProviderFeePaid,
  markProviderFeeRejected,
  markAllSelectedProviderFeePaid,
  markAllSelectedProviderFeeRejected,
} from '../../../services/provider-fee/providerFee.service';
import { providerFeeFilterOptions } from '../payments/Payments.constants';

const ProviderFee: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isLoadingExport, setIsLoadingExport] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isPaidAll, setIsPaidAll] = useState(false);
  const providersList = useSelector((state: AppState) => state.profile.providers2);
  const [filterChangeLoading, setFilterChangeLoading] = useState(false);
  const [resetMultiSelectFilter, setResetMultiSelectFilter] = useState(false);
  const [filterOptionsMock, setFilterOptionsMock] = useState([]);
  const [tableParams, setTableParams] = useState<tableParamsType>({
    search: {
      searchKey: '',
      filter: [],
      dateFilter: {
        startDate: '',
        endDate: '',
      },
    },
    pagination: { currentPage: 1, rowsPerPage: 10 },
    sorter: { direction: 'desc', column: '' },
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [providerFeeList, setProviderFeeList] = useState([]);
  const [selectedProviderFeesIds, setSelectedProviderFeesIds] = useState([]);
  const [selectedFilter] = useState<string>('All');
  const [previousSelectedFilter, setPreviousSelectedFilter] = useState<string>('');
  const [countDetails, setCountDetails] = useState({
    totalFeePaid: 0,
    totalFeeSubmitted: 0,
    totalFeeRejected: 0,
    count: 0,
    totalFeeInsurance: 0,
    totalFeeCash: 0,
    totalFee: 0,
  });

  useEffect(() => {
    if (providersList?.length <= 0) dispatch(profileActionCreators.fetchAllProviders());
  }, []);

  const getFormattedData = (data: IProviderFee[]) => {
    const formatProviderFees = data?.map((providerFee: IProviderFee) => ({
      ...providerFee,
      selected: {
        isChecked: false,
        _id: providerFee?._id,
        status: providerFee?.status,
      },
      member: {
        nickName:
          providerFee?.member?.firstName && providerFee?.member?.lastName
            ? `${providerFee?.member?.firstName || ''} ${providerFee?.member?.lastName || ''}`
            : providerFee?.member?.fullName ?? 'No Name',
        fullName: providerFee?.member?.uuid,
        profileImage: providerFee?.member?.profileImage,
        id: providerFee?.member?.id,
      },
      provider: {
        nickName: providerFee?.provider?.nickName,
        fullName: providerFee?.provider?.firstName
          ? `${providerFee?.provider?.firstName || ''} ${providerFee?.provider?.lastName || ''}`
          : 'N/A',
        profileImage: providerFee?.provider?.profileImage,
        role: providerFee?.provider?.designation || 'N/A',
        id: providerFee?.provider?.id,
      },
      amount: {
        amountPaid: providerFee?.amount,
        increased: providerFee.amount?.increase,
        changed: providerFee.amount?.changed,
      },
      insuranceProvider: {
        insuranceProvider: providerFee?.insuranceDetail?.payer || 'N/A',
        isNoShow: providerFee?.isNoShow || false,
      },
      paidAt: providerFee?.paidAt ?? null,
      actions: { ...providerFee },
    }));

    return formatProviderFees;
  };

  const fetchProviderFeeList = async () => {
    const multiselectFilters = tableParams.search?.multiSelectFilter || {};
    const statuses = multiselectFilters?.Status?.length > 0 ? multiselectFilters?.Status : [];
    const states = multiselectFilters?.State?.length > 0 ? multiselectFilters?.State : [];
    const roles = multiselectFilters?.Role?.length > 0 ? multiselectFilters?.Role : [];
    const filterByPaidAt = multiselectFilters?.['Date filter preference']?.length > 0 || false;

    // const selectedProviders = multiselectFilters?.[DROPDOWN_FILTER_COLLAPSABLE_TYPE.PROVIDERS]?.map(
    //   provider => {
    //     return provider.id;
    //   },
    // );

    try {
      setIsLoading(true);
      const {
        pagination: { rowsPerPage, currentPage },
      } = tableParams;
      const queryParams = {
        // providerId: selectedProviders,
        page: currentPage,
        limit: rowsPerPage,
      };
      const body = {
        searchQuery: tableParams?.search?.searchKey,
        startDate: tableParams?.search?.dateFilter?.startDate
          ? dayjs(tableParams?.search?.dateFilter?.startDate)?.toISOString()
          : null,
        endDate: tableParams?.search?.dateFilter?.endDate
          ? dayjs(tableParams?.search?.dateFilter?.endDate)?.toISOString()
          : null,
        statuses,
        states,
        roles,
        providerIds: multiselectFilters[DROPDOWN_FILTER_COLLAPSABLE_TYPE.PROVIDERS]?.map(provider => {
          return provider.id;
        }),
        filterByPaidAt,
        // states: getFilterForState(),
      };

      const response = await getProviderFeeList(queryParams, body);
      setProviderFeeList(getFormattedData(response?.data?.data));
      setCountDetails(response?.data?.metadata);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedFilter !== previousSelectedFilter) {
      setPreviousSelectedFilter(selectedFilter);
    }
    setSelectedProviderFeesIds([]);
    setIsCheckAll(false);

    void fetchProviderFeeList();
  }, [tableParams, selectedFilter]);

  const markProviderFeePaidOnScreen = async (data?: any) => {
    try {
      const response = await markProviderFeePaid({ id: data?._id });
      if (response.status === 200) {
        setIsCheckAll(false);
        setSelectedProviderFeesIds([]);
        await fetchProviderFeeList();
        dispatch(
          showSnackbar({
            snackType: 'success',
            snackMessage: response?.data?.data?.message || 'Status updated successfully',
          }),
        );
      } else {
        dispatch(
          showSnackbar({
            snackType: 'error',
            snackMessage: response?.data?.data?.message || 'Something went wrong!Please try again !',
          }),
        );
      }
    } catch (error) {
      console.log(error);
      dispatch(
        showSnackbar({
          snackType: 'error',
          snackMessage: 'Something went wrong! Please try again',
        }),
      );
    }
  };

  const updateSelectedProviderFeeIds = list => {
    const selectedArray = list
      .filter(item => item.selected.isChecked)
      .map(row => {
        return row.selected._id;
      });
    setSelectedProviderFeesIds(selectedArray);
  };
  const markProviderFeeRejectedOnScreen = async data => {
    try {
      const response = await markProviderFeeRejected({ id: data?._id });
      if (response?.status === 200) {
        setIsCheckAll(false);
        setSelectedProviderFeesIds([]);
        fetchProviderFeeList()
          .then(r => console.log(r))
          .catch(e => console.log(e));
        dispatch(
          showSnackbar({
            snackType: 'success',
            snackMessage: response?.data?.data?.message || 'Status updated successfully',
          }),
        );
      } else {
        dispatch(
          showSnackbar({
            snackType: 'error',
            snackMessage: response?.data?.data?.message || 'Something went wrong! Please try again',
          }),
        );
      }
    } catch (error) {
      console.log(error);
      dispatch(
        showSnackbar({
          snackType: 'error',
          snackMessage: 'Something went wrong! Please try again',
        }),
      );
    }
  };

  const confirmMultipleSelectAction = (isMultiplePay: boolean) => {
    setIsPaidAll(isMultiplePay);
    setShowConfirmModal(true);
  };

  const onMultipleSelectAction = async () => {
    try {
      let response;
      if (isPaidAll) {
        response = await markAllSelectedProviderFeePaid({ ids: selectedProviderFeesIds });
      } else {
        response = await markAllSelectedProviderFeeRejected({ ids: selectedProviderFeesIds });
      }
      if (response.status === 200) {
        setShowConfirmModal(false);
        setIsCheckAll(false);
        setSelectedProviderFeesIds([]);
        fetchProviderFeeList()
          .then(r => console.log(r))
          .catch(e => console.log(e));
        dispatch(
          showSnackbar({
            snackType: 'success',
            snackMessage: response?.data?.data?.message || 'Status updated successfully',
          }),
        );
      } else {
        dispatch(
          showSnackbar({
            snackType: 'error',
            snackMessage: response?.data?.data?.message || 'Something went wrong!Please try again !',
          }),
        );
      }
    } catch (error) {
      dispatch(
        showSnackbar({
          snackType: 'error',
          snackMessage: 'Something went wrong! Please try again',
        }),
      );
    }
  };

  const onRowClick = (data: IInvoiceRecord) => {
    setSelectedItem(data);
  };

  const onSelectAllClick = (isSelectAll: boolean) => {
    setIsCheckAll(isSelectAll);
    const currentCheck = isSelectAll;
    setProviderFeeList(prevState => {
      const updatedProviderFeeList = [...prevState];
      updatedProviderFeeList.forEach(item => {
        if (!['Paid', 'Rejected'].includes(item.status)) {
          item.selected.isChecked = currentCheck;
        }
      });
      updateSelectedProviderFeeIds(updatedProviderFeeList);
      return updatedProviderFeeList;
    });
  };

  const renderColumns = useCallback(() => {
    return tableColumns.map(column => {
      if (column.id === 'timeStamp') {
        return {
          ...column,
          renderCell: (timeStamp: string) => {
            return (
              <div className={classes.timeWrap}>
                <div className={classes.date}>{dayjs(timeStamp).format('MM/DD/YYYY')}</div>
                <div className={classes.time}>{dayjs(timeStamp).format('h:mm A')}</div>
              </div>
            );
          },
        };
      }
      if (column.id === 'member') {
        return {
          ...column,
          renderCell: ({ nickName, fullName, profileImage, id }) => (
            <ProfileInfo
              type="member"
              photo={profileImage}
              nickName={nickName || 'N/A'}
              fullName={fullName || 'N/A'}
              memberId={id}
            />
          ),
        };
      }
      if (column.id === 'provider') {
        return {
          ...column,
          renderCell: ({ profileImage, fullName, role, nickName, id }) => (
            <ProfileInfo
              type="provider"
              photo={profileImage}
              role={role}
              fullName={fullName || 'N/A'}
              nickName={nickName || 'N/A'}
              memberId={id}
            />
          ),
        };
      }
      if (column.id === 'amount') {
        return {
          ...column,
          renderCell: ({ amountPaid }) => <span>${amountPaid || '0'}</span>,
        };
      }
      if (column.id === 'selected') {
        return {
          ...column,
          renderCell: ({ isChecked, _id, status }) => (
            <Checkbox
              disabled={status === 'Paid' || status === 'Rejected'}
              checked={isChecked}
              onChange={event => {
                const currentCheck = event.target.checked;
                setProviderFeeList(prevState => {
                  const index = prevState.findIndex(item => item._id === _id);
                  const updatedProviderFeeList = [...prevState];
                  updatedProviderFeeList[index].selected.isChecked = currentCheck;
                  updateSelectedProviderFeeIds(updatedProviderFeeList);
                  return updatedProviderFeeList;
                });
              }}
            />
          ),
        };
      }
      if (column.id === 'insuranceProvider') {
        return {
          ...column,
          renderCell: ({ insuranceProvider, isNoShow }) => (
            <>
              {' '}
              <span>{insuranceProvider}</span>
              {isNoShow && (
                <Text weight={fontWeight.MEDIUM} className={classes.noShowLabel}>
                  No show
                </Text>
              )}
            </>
          ),
        };
      }
      if (column.id === 'state') {
        return {
          ...column,
          renderCell: state => <span>{state || 'N/A'}</span>,
        };
      }
      if (column.id === 'paidAt') {
        return {
          ...column,
          renderCell: (paidAt: string) => {
            return paidAt ? (
              <div className={classes.timeWrap}>
                <div className={classes.date}>{dayjs(paidAt).format('MMMM D, YYYY')}</div>
                <div className={classes.time}>{dayjs(paidAt).format('h:mm A')}</div>
              </div>
            ) : (
              '-'
            );
          },
        };
      }
      if (column.id === 'status') {
        return {
          ...column,
          renderCell: (val: any) => {
            return (
              <Badge
                variant={badgeType.OUTLINED}
                style={
                  val === 'Paid'
                    ? badgeStyle.RESOLVED
                    : val === 'Submitted' || val === 'Created'
                    ? badgeStyle.PRIMARY
                    : badgeStyle.HIGH
                }
                className={clsx(
                  classes.status,
                  { [classes.unpaidStatus]: val === 'Rejected' },
                  { [classes.submittedStatus]: val === 'Submitted' || val === 'Created' },
                )}
              >
                {val}
              </Badge>
            );
          },
        };
      }

      if (column.id === 'cptList') {
        return {
          ...column,
          renderCell: cptList => (
            <Box>
              {cptList?.length > 0
                ? cptList?.map((cpt, index) => cpt && <Box key={index}>{cpt?.cptCode}</Box>)
                : 'N/A'}
            </Box>
          ),
        };
      }
      if (column.id === 'actions') {
        return {
          ...column,
          renderCell: data =>
            (data?.status === 'Submitted' || data?.status === 'Created') && (
              <Menu
                icon="more"
                className={classes.menu}
                itemsWrapperClassName={classes.menuItemsWrapper}
                items={[
                  {
                    label: 'Mark as Paid',
                    onClick: async () => {
                      await markProviderFeePaidOnScreen(data);
                    },
                  },
                  {
                    label: 'Mark as Rejected',
                    onClick: async () => {
                      await markProviderFeeRejectedOnScreen(data);
                    },
                  },
                ]}
              />
            ),
        };
      }
      return column;
    });
  }, []);

  const onCloseDrawer = () => {
    setSelectedItem(null);
  };

  const renderStatusTotal = (status: string, value: number) => (
    <Box
      padding={2}
      sx={{
        background: colors.white,
        flex: '1 1 0',
        boxShadow:
          '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
        borderRadius: '8px',
      }}
    >
      <Box display="flex" justifyContent="space-between">
        <Text className={classes.label}>TOTAL FEES {status.toUpperCase()}</Text>
        <Badge
          className={clsx(
            classes.statusLabel,
            { [classes.unpaidStatus]: status !== 'Paid' },
            { [classes.submittedStatus]: status === 'Submitted' },
          )}
          variant={badgeType.OUTLINED}
          style={
            status === 'Paid'
              ? badgeStyle.RESOLVED
              : status === 'Submitted'
              ? badgeStyle.PRIMARY
              : badgeStyle.HIGH
          }
        >
          {status !== '' ? status : 'Total'}
        </Badge>
      </Box>
      <Text className={classes.statusFee}>${Number.isInteger(value) ? value : value.toFixed(2)}</Text>
    </Box>
  );

  const onExportClick = () => {
    const multiselectFilters = tableParams.search?.multiSelectFilter || {};
    const statuses = multiselectFilters?.Status?.length > 0 ? multiselectFilters?.Status : [];
    const states = multiselectFilters?.State?.length > 0 ? multiselectFilters?.State : [];
    const roles = multiselectFilters?.Role?.length > 0 ? multiselectFilters?.Role : [];
    const filterByPaidAt = multiselectFilters?.['Date filter preference']?.length > 0 || false;
    const queryParams = {
      download: true,
      page: 1,
      limit: countDetails?.count || 10000,
    };
    const body = {
      searchQuery: tableParams?.search?.searchKey,
      startDate: tableParams?.search?.dateFilter?.startDate
        ? dayjs(tableParams?.search?.dateFilter?.startDate)?.toISOString()
        : null,
      endDate: tableParams?.search?.dateFilter?.endDate
        ? dayjs(tableParams?.search?.dateFilter?.endDate)?.toISOString()
        : null,
      statuses,
      states,
      roles,
      providerIds:
        multiselectFilters[DROPDOWN_FILTER_COLLAPSABLE_TYPE.PROVIDERS]?.map(provider => {
          return provider.id;
        }) || [],
      filterByPaidAt,
    };

    setIsLoadingExport(true);
    downloadProviderFeeList(queryParams, body)
      .then(res => {
        const blob = new Blob([res.data], {
          type: 'application/vnd.ms-excel',
        });

        saveAs(blob, 'Provider fee list.xlsx');
        setIsLoadingExport(false);
      })
      .catch(err => {
        console.warn(err);
        dispatch(
          showSnackbar({
            snackType: 'error',
            snackMessage: 'Error downloading!',
          }),
        );
        setIsLoadingExport(false);
      });
  };

  const closeConfirmation = () => {
    setShowConfirmModal(false);
  };

  useEffect(() => {
    setFilterOptionsMock(multiSelectFilterOptionsMock(providersList));
    setResetMultiSelectFilter(true);
  }, [providersList]);

  useEffect(() => {
    setResetMultiSelectFilter(false);
  }, [tableParams]);

  useEffect(() => {
    setFilterChangeLoading(true);
    setTimeout(() => {
      setFilterChangeLoading(false);
    }, 100);
  }, [filterOptionsMock]);

  return (
    <BaseLayout>
      <ProviderFeeDetailDrawer
        open={!!selectedItem}
        onClose={onCloseDrawer}
        invoice={selectedItem}
        onMarkAsPaid={async () => {
          await markProviderFeePaidOnScreen(selectedItem);
        }}
        onMarkAsReject={async () => {
          await markProviderFeeRejectedOnScreen(selectedItem);
        }}
      />
      <Box className={classes.root}>
        <Stack direction="row" justifyContent="space-between" spacing={2} sx={{ marginBottom: 5 }}>
          <Stack direction="row" alignItems="center" gap={2} sx={{ height: 48 }}>
            <Heading className={classes.heading} level={headingLevel.XL} weight={fontWeight.BOLD}>
              Provider Fee
            </Heading>
            <Badge className={classes.totalBadge} variant={badgeType.OUTLINED} style={badgeStyle.UNRELATED}>
              {countDetails?.count || '0'} total
            </Badge>
          </Stack>
          <Stack direction="row" gap={2}>
            <IconButton
              icon="dollar-square"
              variant={btnType.PRIMARY}
              className={classes.rejectBtn}
              onClick={() => confirmMultipleSelectAction(false)}
              disabled={selectedProviderFeesIds.length <= 0}
            >
              Reject Multiple
            </IconButton>
            <IconButton
              icon="dollar-square"
              variant={btnType.PRIMARY}
              className={classes.addBtn}
              onClick={() => confirmMultipleSelectAction(true)}
              disabled={selectedProviderFeesIds.length <= 0}
            >
              Pay Multiple
            </IconButton>
          </Stack>
        </Stack>
        <Box display="flex" flexDirection="column" mb={4} gap={0}>
          {/* <Box display="flex" mb={2} gap={2}>
            {renderStatusTotal('', countDetails?.totalFee)}
            {renderStatusTotal('Insurance', countDetails?.totalFeeInsurance)}
            {renderStatusTotal('Cash', countDetails?.totalFeeCash)}
          </Box> */}
          <Box display="flex" mb={2} gap={2}>
            {renderStatusTotal('Submitted', countDetails?.totalFeeSubmitted || 0)}
            {renderStatusTotal('Paid', countDetails?.totalFeePaid || 0)}
            {renderStatusTotal('Rejected', countDetails?.totalFeeRejected || 0)}
          </Box>
        </Box>
        <div className={classes.providerFeeList}>
          <ConfirmationModal
            open={showConfirmModal}
            onClose={closeConfirmation}
            onSave={onMultipleSelectAction}
            title="Pay/Reject Multiple Invoices"
            message="Are you sure?"
          />
          {filterChangeLoading ? (
            <div className={classes.loader}>
              <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
              Loading...
            </div>
          ) : (
            <Table
              searchProps={{
                placeholder: 'Search member and provider',
                filterProps: {
                  variant: Filter.tableFilterType.MULTIPLE,
                  options: providerFeeFilterOptions,
                  multiSelectOptions: filterOptionsMock,
                  dateFilter: {
                    startDate: tableParams?.search?.dateFilter?.startDate,
                    endDate: tableParams?.search?.dateFilter?.endDate,
                  },
                },
                resetMultiSelectFilter,
                exportProps: {
                  btnTitle: 'Export',
                  isLoadingExport,
                },
              }}
              onClickExport={onExportClick}
              gridProps={{
                columns: renderColumns(),
                data: providerFeeList || [],
                isLoading,
                onRowClick,
                isCheckAll,
                onSelectAllClick,
              }}
              paginationProps={{
                currentRows: providerFeeList?.length || 0,
                totalCount: countDetails?.count || 0,
                showRowsPerPage: true,
              }}
              value={tableParams}
              onChange={(newValue: any) => {
                setTableParams({
                  ...newValue,
                  search: {
                    ...newValue.search,
                    filter: newValue?.search?.filter.filter(
                      (item, pos) => newValue?.search?.filter?.indexOf(item) === pos,
                    ),
                  },
                });
              }}
            />
          )}
        </div>
      </Box>
    </BaseLayout>
  );
};

export { ProviderFee };
