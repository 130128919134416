// colors
import { colors } from '@confidant-health/lib/colors';
import { makeStyles } from '@mui/styles';

interface StyleProps {
  lottieSize?: number;
}

export const useStyles = makeStyles({
  noContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 40,
    // height: 'calc(100vh - 300px)',
    justifyContent: 'center',
    maxWidth: 480,
    margin: 'auto',
    '&.session-evaluations': {
      padding: 32,
      '& $title': {
        fontSize: 18,
        lineHeight: '24px',
        marginBottom: 8,
      },
    },
  },
  alfie: {
    width: 200,
    height: 200,
  },
  alfie_portrait: {
    width: 'auto',
    maxHeight: 350,
    height: '100%',
  },
  alifie_landscape: {
    height: 'auto',
    width: '100%',
    maxWidth: 500,
  },
  title: {
    fontSize: 28,
    lineHeight: '36px',
    fontWeight: 700,
    marginBottom: 16,
    textAlign: 'center',
  },
  description: {
    fontSize: 16,
    lineHeight: '24px',
    textAlign: 'center',
    fontWeight: 500,
    color: colors.neutral600,
    display: 'block',
  },
  addBtn: {
    height: 64,
    color: '#FFF',
    padding: '20px 24px',
    borderRadius: 8,
  },
  lottieSize: {
    width: ({ lottieSize }: StyleProps) => lottieSize,
    height: ({ lottieSize }: StyleProps) => lottieSize,
  },
});
