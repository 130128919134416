import { makeStyles } from '@mui/styles';
import { colors } from '@confidant-health/lib/colors';

export const useStyles = makeStyles({
  boxContainer: {
    position: 'absolute',
    bottom: '200vh',
    left: 0,
    right: 0,
    height: '12rem',
    background: '#fff',
    borderTop: '1px solid #E5EBF0',
    zIndex: 1,
    transform: 'translateY(100%)',
    opacity: 0,
    transition: 'transform 0.6s cubic-bezier(0.25, 1, 0.5, 1), opacity 0.6s ease',
  },
  boxContainerVisible: {
    transform: 'translateY(0)',
    opacity: 1,
    bottom: 0,
  },
  boxHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #E5EBF0',
    padding: '10px 16px',
  },
  closeBtn: {
    minWidth: 'auto',
    '& svg': {
      width: 24,
      color: colors.neutral900,
    },
  },
  headerIcon: {
    width: '20px',
    height: '20px',
  },
  headerIconLarge: {
    width: '26px',
    height: '26px',
  },
  boxBody: {
    display: 'flex',
    flexDirection: 'column',
    padding: '8px 16px',
  },
  bodyTitle: {
    fontSize: '14px',
    color: colors.neutral900,
    lineHeight: '2rem',
    '& span': { fontWeight: 700 },
  },
  bodySubTitle: {
    fontSize: '12px',
    color: colors.neutral500,
    lineHeight: '2rem',
    '& span': { fontWeight: 700 },
  },
  boxFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
    borderTop: '1px solid #E5EBF0',
    padding: '8px 16px',
  },
  footerBtn: {
    fontSize: '14px',
    color: colors.primary500,
    border: `1px solid ${colors.primary500}`,
    padding: '10px',
    gap: '5px',
    '& svg': {
      width: '18px',
    },
  },
});
